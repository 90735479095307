import api from '@/api/msg'
import axios from '@/config/axios'
import { BaseParamsType, BaseResultType, ResultType } from '@/types/request'
import { checkResult, dealResult } from '@/utils/request'
import { sysMsg } from 'wd-platform-library'
// 查询消息头
export const selectMsgTitleList = async ():Promise<BaseResultType<MessageTitleResult[]> | null> => {
    const [result, err] = await axios.get(api.messageTitleList)
    return Promise.resolve(dealResult(err, result, sysMsg))
}
/**
 * 消息列表
 */
export const selectMesageList = async (param: BaseParamsType<{ messageTypeId: string }>): Promise<BaseResultType<ResultType<MessageResult>> | null> => {
    const [result, err] = await axios.post(api.messageList, param)
    return Promise.resolve(dealResult(err, result, sysMsg))
}
/**
 * 已读
 */
export const batchReadMsg = async (param: string[]): Promise<string|null> => {
    const [result, err] = await axios.post(api.readMsg, param)
    return Promise.resolve(checkResult(err, result, sysMsg))
}
/**
 * 删除
 */
export const batchDelMsg = async (param: string[]): Promise<string|null> => {
    const [result, err] = await axios.post(api.delMsg, param)
    return Promise.resolve(checkResult(err, result, sysMsg))
}
