import NavTag from '@/components/NavTag/index.vue'
import { changeUserOrg } from '@/service/userService'
import { useUserStore } from '@/store/modules/user'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SysMessang from './SysMessang.vue'
import userICon from '@/assets/img/favicon64.png'
import { useLoadingStore } from '@/store/modules/loading'
export default defineComponent({
    name: 'WdHeader',
    components: {
        NavTag, SysMessang
    },
    setup () {
        const loadingStore = useLoadingStore()
        const userStore = useUserStore()
        const route = useRoute()
        const router = useRouter()
        const sysMsgRef = ref()
        const count = ref<number>(5)
        const userInfo = ref<User | null>(null)
        userStore.$subscribe((_: any, state) => {
            userInfo.value = state.userInfo
        })
        const handlerChangeOrgFun = async (org: OrgList) => {
            loadingStore.setLoadingState(true)
            const resultData = await changeUserOrg({
                orgId: org.orgId
            })
            if (resultData) {
                window.location.reload()
            }
            loadingStore.setLoadingState(false)
        }
        const handlerLoginOut = () => {
            userStore.setToken(null)
            router.push('/login')
        }
        const onClickMsgFun = () => {
            sysMsgRef.value.init()
        }
        onMounted(() => {
            userInfo.value = userStore.getUserInfo
        })
        return {
            userICon,
            count,
            onClickMsgFun,
            sysMsgRef,
            route,
            userInfo,
            handlerLoginOut,
            handlerChangeOrgFun
        }
    }
})
