import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20597fea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "msg-content" }
const _hoisted_2 = {
  key: 0,
  style: {"color":"#00000040"}
}
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ITabs = _resolveComponent("ITabs")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_i_vxe_table = _resolveComponent("i-vxe-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_i_dialog = _resolveComponent("i-dialog")!

  return (_openBlock(), _createBlock(_component_i_dialog, {
    dialogData: _ctx.dialogData,
    class: "msg-content-dialog"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_layout_header, null, {
              default: _withCtx(() => [
                _createVNode(_component_ITabs, {
                  tabsOption: _ctx.tabsOption,
                  onChangTab: _ctx.changTab
                }, null, 8 /* PROPS */, ["tabsOption", "onChangTab"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_a_layout_content, null, {
              default: _withCtx(() => [
                _createVNode(_component_i_vxe_table, {
                  ref: "msgTableRef",
                  onReadMsgFun: _ctx.readMsgFun,
                  tableData: _ctx.tableData,
                  onSelectCheckBox: _ctx.selectCheckBox
                }, {
                  messageContent_template: _withCtx(({obj}) => [
                    (obj.status === '1')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(obj.messageContent), 1 /* TEXT */))
                      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(obj.messageContent), 1 /* TEXT */))
                  ]),
                  operation_template: _withCtx(({selectRow}) => [
                    (_ctx.checkBoxList.length>0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createVNode(_component_a_button, {
                            type: "primary",
                            style: {"margin-right":"16px"},
                            onClick: ($event: any) => (_ctx.readMsgFun(selectRow))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("批量已读")
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                          _createVNode(_component_a_button, {
                            type: "primary",
                            onClick: ($event: any) => (_ctx.delMsgFun(selectRow)),
                            style: {}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("批量删除")
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                        ], 64 /* STABLE_FRAGMENT */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onReadMsgFun", "tableData", "onSelectCheckBox"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["dialogData"]))
}