import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b67076a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.activePath,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activePath) = $event)),
      "hide-add": "",
      type: "editable-card",
      onTabClick: _ctx.tabClickFun,
      onEdit: _ctx.handlerCloseTab
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (item) => {
          return (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: item.url,
            tab: item.title,
            closable: item.closable
          }, null, 8 /* PROPS */, ["tab", "closable"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["activeKey", "onTabClick", "onEdit"])
  ]))
}