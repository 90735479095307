import { router } from '@/router'
import { useMenuStore } from '@/store/modules/menu'
import { useUserStore } from '@/store/modules/user'
import { defineComponent, onMounted, ref, toRaw, watch } from 'vue'
import { useRoute } from 'vue-router'
interface TabList {
    title: string
    url: string,
    closable: boolean
}
export default defineComponent({
    name: 'i_nav_tag',
    setup () {
        const route = useRoute()
        const menuStore = useMenuStore()
        const userStore = useUserStore()
        const menuList = ref<MenuList[]>([])
        menuList.value = menuStore.getMenuList || []
        menuStore.$subscribe((_:any, state) => {
            menuList.value = state.menuList || []
            initTabList()
        })
        const userData = ref<User| null>(null)
        userData.value = userStore.getUserInfo
        userStore.$subscribe((_:any, state) => {
            userData.value = state.userInfo
            initTabList()
        })
        const tabList = ref<Array<any>>([])
        const activePath = ref('')

        // 关闭回调
        const handlerCloseTab = (path:string) => {
            const lastIndex = tabList.value.findIndex(el => el.url === path) || 0
            tabList.value = tabList.value.filter(pane => pane.url !== path)
            if (tabList.value.length && activePath.value === path) {
                activePath.value = lastIndex - 1 >= 0 ? tabList.value[lastIndex - 1].url : tabList.value[0].url
                tabClickFun(activePath.value)
            }
        }
        // 跳转方法
        const tabClickFun = (path: any) => {
            router.push(path)
        }

        const initTabList = () => {
            const tempMenu = JSON.parse(JSON.stringify(toRaw(tabList.value))) as TabList[]
            const isExist = tempMenu.some(el => el.url === route.path)
            const appId = route.path.split('/')[1]
            const menu = menuList.value.find(el => el.menuCode === appId)
            const appInfo = userData.value?.applicationList.find(el => el.appId === appId)
            if (menu) {
                const m = (menu.menuList || []).find(el => `/${appId}${el.href}` === route.path)
                if (m && !isExist) {
                    tempMenu.push({ title: m.menuName, url: route.path, closable: true })
                }
            } else if (appInfo) {
                if (!isExist) {
                    tempMenu.push({
                        title: appInfo.applicationName,
                        url: `/${appId}/`,
                        closable: false
                    })
                }
            }
            tabList.value = [...tempMenu]
        }
        onMounted(() => {
            activePath.value = route.path
            initTabList()
        })
        watch(() => route.path, () => {
            activePath.value = route.path
            initTabList()
        })
        return {
            tabList,
            activePath,
            handlerCloseTab,
            tabClickFun
        }
    }
})
